import type { Environment } from './types';
import { sharedDevEnv, sharedDevEuEnv, sharedCompoundUkEnv } from './shared';

export const env: Environment = {
  ...sharedDevEnv,
  ...sharedCompoundUkEnv,
  ...sharedDevEuEnv,
  adminAppUrl: 'https://admin.dev.uk.compound.co',
  logoUrl:
    'https://storage.googleapis.com/assets-compound-uk/compound-logo.svg',
};
